
import { VueAgile } from 'vue-agile'
import { linkMixin } from '~/mixins'
export default {
  name: 'CarouselSix',
  components: {
    VueAgile,
  },
  mixins: [linkMixin],
  props: {
    mainSlider: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      mainSliderActive: 0,
      sliderOptions: {
        fade: true,
        navButtons: false,
        dots: false,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 1024,
          },
        ],
      },
    }
  },
  computed: {
    linkUrl() {
      return this.mainSlider[this.mainSliderActive]?.ltCtaLink || ''
    },
    currentLocale() {
      return this.$i18n.localeProperties.code
    },
    activeDescription() {
      return this.mainSlider[this.mainSliderActive]?.description || ''
    },
  },
  methods: {
    setActiveToMain({ currentSlide }) {
      this.mainSliderActive = currentSlide
    },
  },
}
